:root {
  --primary-color: #ff9cb1;
  --gray-color: #303336;
}

body {
  margin-top: 100px;
}

p {
  color: var(--gray-color);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  list-style-type: none;
  text-decoration: none;
  color: inherit;
}

@media (min-width: 769px) {
  .header-list {
    display: flex;
  }

  .header-list-item {
    margin-right: 40px;
    color: var(--gray-color);
    cursor: pointer;
  }

  .header-list-item:last-child {
    margin: 0;
  }

  .hamburger-nav {
    display: none;
  }

  .home-jumbotron-container {
    height: 640px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  .home-jumbotron-button-container {
    position: absolute;
    width: 100%;
    bottom: 50px;
    display: flex;
    justify-content: center;
  }

  .primary-button {
    height: 44px;
    padding: 0 24px;
    background-color: var(--primary-color);
    border: 2px solid transparent;
    border-radius: 10px;
    color: white;
    cursor: pointer;
  }

  .secondary-button {
    height: 44px;
    padding: 0 24px;
    background-color: white;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    cursor: pointer;
  }

  .jumbotron-button {
    margin-right: 32px;
  }

  .jumbotron-button:last-child {
    margin-right: 0px;
  }

  .section-header {
    color: var(--primary-color);
    font-size: 48px;
  }

  .home-mission-statement-container {
    padding: 64px 128px;
  }

  .home-mission-statement-info-container {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }

  .home-mission-statement-image-container {
    height: 300px;
    width: 300px;
    margin-right: 32px;
    flex-shrink: 0;
  }

  .section-sub-header {
    color: var(--primary-color);
    font-size: 36px;
  }

  .home-book-appointment-header {
    color: white;
    margin-bottom: 32px;
  }

  .about-us-container {
    padding: 64px 128px;
    display: flex;
  }

  .about-us-image-container {
    width: 560px;
    height: 560px;
    margin-right: 96px;
    flex-shrink: 0;
  }

  .about-us-header {
    margin-bottom: 64px;
  }

  .our-services-card-container {
    padding: 10px 64px;
    display: flex;
    justify-content: space-evenly;
  }

  .areas-we-serve-image-container {
    height: 640px;
    width: 640px;
  }

  .packages-tab-header {
    color: white;
    margin-right: 32px;
    font-weight: 400;
    cursor: pointer;
    padding-bottom: 8px;
    margin-bottom: 32px;
  }

  .packages-container {
    margin-top: 64px;
    background-color: var(--primary-color);
    padding: 64px 128px;
  }

  .service-card {
    width: 256px;
    flex-shrink: 0;
    margin-bottom: 32px;
  }

  .service-card:nth-child(1) .service-card-image {
    object-position: center right;
  }

  .service-card:nth-child(2) .service-card-image {
    object-position: center left;
  }

  .header-container {
    height: 100px;
    padding: 0 128px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100000;
    background-color: white;
    box-shadow: 0 2.5px 2.5px rgba(0, 0, 0, 0.063);
  }

  .contact-us-jumbotron-container {
    height: 320px;
    position: relative;
  }

  .contact-us-form-container {
    padding: 64px 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-us-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    flex-shrink: 0;
    width: 100%;
  }

  .input-field {
    position: absolute;
    border-radius: inherit;
    border: none;
    padding-left: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    outline: none;
  }

  .modal {
    width: 640px;
    height: 380px;
    background-color: white;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    text-align: center;
  }

  .home-jumbotron-header {
    z-index: 1;
    color: white;
    font-size: 116px;
  }

  .home-jumbotron-text-container {
    z-index: 1;
    margin-bottom: 240px;
    text-align: center;
    color: white;
  }

  .input-field-container {
    position: relative;
    width: 80%;
    max-width: 480px;
    height: 40px;
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    margin-bottom: 52px;
  }
}

@media (max-width: 992px) {
  .about-us-container {
    padding: 32px 64px;
    display: flex;
    flex-direction: column;
  }

  .about-us-image-container {
    height: 300px;
    width: 100%;
    margin-bottom: 36px;
    flex-shrink: 0;
  }

  .about-us-header {
    margin-bottom: 32px;
  }
}

@media (max-width: 768px) {
  .header-list {
    display: none;
  }

  .header-list-item {
    padding: 8px;
    margin-bottom: 40px;
    width: 110px;
    color: var(--gray-color);
    cursor: pointer;
  }

  .header-list-item:last-child {
    margin: 0;
  }

  .hamburger-nav {
    cursor: pointer;
    font-size: 24px;
    padding: 16px;
    margin: -16px;
  }

  .home-jumbotron-container {
    height: 560px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }

  .home-jumbotron-button-container {
    position: absolute;
    width: 100%;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .primary-button {
    height: 44px;
    width: 200px;
    padding: 0 24px;
    background-color: var(--primary-color);
    border: 2px solid transparent;
    border-radius: 10px;
    color: white;
    cursor: pointer;
  }

  .secondary-button {
    height: 44px;
    width: 200px;
    padding: 0 24px;
    background-color: white;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    cursor: pointer;
  }

  .jumbotron-button {
    margin-bottom: 24px;
  }

  .jumbotron-button:last-child {
    margin-bottom: 0px;
  }

  .section-header {
    color: var(--primary-color);
    font-size: 32px;
  }

  .home-mission-statement-container {
    padding: 32px 64px;
  }

  .home-mission-statement-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
  }

  .home-mission-statement-image-container {
    height: 300px;
    width: 100%;
    margin-bottom: 32px;
    flex-shrink: 0;
  }

  .section-sub-header {
    color: var(--primary-color);
    font-size: 16px;
  }

  .home-book-appointment-header {
    color: white;
    width: 200px;
    text-align: center;
    margin-bottom: 24px;
  }

  .our-services-card-container {
    padding: 16px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .areas-we-serve-image-container {
    height: 300px;
    width: 300px;
  }

  .packages-tab-header {
    color: white;
    margin-right: 24px;
    font-weight: 400;
    cursor: pointer;
    padding-bottom: 8px;
    margin-bottom: 32px;
    font-size: 10px;
  }

  .packages-container {
    margin-top: 64px;
    background-color: var(--primary-color);
    padding: 16px 64px;
  }

  .service-card {
    width: 256px;
    flex-shrink: 0;
    margin-bottom: 32px;
  }

  .header-container {
    height: 100px;
    padding: 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2.5px 2.5px rgba(0, 0, 0, 0.063);
  }

  .contact-us-form-container {
    padding: 16px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-us-grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    flex-shrink: 0;
    width: 100%;
  }

  .input-field {
    position: absolute;
    border-radius: inherit;
    border: none;
    padding-left: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    outline: none;
    font-size: 10px;
  }

  .contact-us-jumbotron-container {
    height: 160px;
    position: relative;
  }

  .modal {
    width: 320px;
    height: 400px;
    background-color: white;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    text-align: center;
  }

  .home-jumbotron-header {
    z-index: 1;
    font-size: 60px;
    color: white;
  }

  .home-jumbotron-text-container {
    color: white;
    margin-bottom: 240px;
    z-index: 1;
    text-align: center;
    font-size: 10px;
  }

  .input-field-container {
    position: relative;
    width: 100%;
    height: 40px;
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    margin-bottom: 52px;
  }
}

.black-border {
  border: 2px solid black;
}

.header-logo {
  width: 192px;
  margin-left: -16px;
  color: var(--primary-color);
  cursor: pointer;
}

.drawer-logo {
  width: 128px;
  color: var(--primary-color);
  cursor: pointer;
  margin-left: -9px;
}

.selected-nav {
  color: var(--primary-color);
}

.about-us-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.home-jumbotron-image {
  object-fit: cover;
  object-position: center center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.primary-button:hover {
  background-color: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.secondary-button:hover {
  background-color: var(--primary-color);
  color: white;
  border: 2px solid transparent;
}

.home-mission-statement-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
}

.home-book-appointment-container {
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
}

.home-mission-statement-paragraph {
  font-size: 16px;
}

.home-book-appointment-header {
  color: white;
  margin-bottom: 32px;
}

.home-book-appointment-button:hover {
  border: 2px solid white;
}

.about-us-info-paragraph {
  margin-bottom: 32px;
}

.our-services-header {
  color: var(--gray-color);
  margin-top: 128px;
  margin-bottom: 64px;
  text-align: center;
}

.service-card-image {
  height: 256px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.service-card-title {
  display: inline-block;
  color: var(--primary-color);
  margin: 16px 0;
  font-weight: 500;
}

.packages-header {
  color: white;
  text-align: center;
  margin-bottom: 32px;
}

.packages-tab-header-container {
  display: flex;
}

.active-package {
  border-bottom: 2px solid white;
}

.package-text {
  color: white;
  margin-bottom: 32px;
}

.areas-we-serve-container {
  padding: 64px 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.areas-we-serve-header {
  text-align: center;
  margin-bottom: 32px;
}

.areas-we-serve-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contact-us-header {
  margin-bottom: 64px;
  text-align: center;
}

.input-field-label {
  position: absolute;
  left: 16px;
  top: -14px;
  background-color: white;
  font-size: 10px;
  font-weight: 700;
  padding: 4px;
  color: var(--gray-color);
}

.drawer-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  animation-name: open-drawer-background;
  animation-duration: 225ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes open-drawer-background {
  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.drawer-container {
  width: 194px;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: white;
  animation-name: open-drawer;
  animation-duration: 225ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  padding: 32px 32px;
}

@keyframes open-drawer {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

.drawer-list {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}

.drawer-x {
  color: var(--gray-color);
  position: absolute;
  right: 16px;
  top: 24px;
  padding: 16px;
  font-size: 24px;
  cursor: pointer;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.error-message {
  position: absolute;
  top: 100%;
  color: red;
  font-size: 12px;
  margin: 8px;
}
