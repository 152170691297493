.container {
  padding: 64px 0;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
}

.phone_number {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
