.header {
  text-align: center;
  margin-bottom: 32px;
}

@media (max-width: 768px) {
  .grid_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    flex-shrink: 0;
    width: 100%;
    gap: 16px;
  }

  .container {
    padding: 64px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 769px) {
  .grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    flex-shrink: 0;
    width: 100%;
    gap: 16px;
  }

  .container {
    padding: 64px 128px;
    display: flex;
    flex-direction: column;
  }
}
