.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .container {
    height: 250px;
    width: 100%;
    margin-bottom: 32px;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .container {
    height: 200px;
    width: 100%;
    margin-bottom: 32px;
    text-align: center;
  }
}
